<template>
  <div>
    <!-- ======= MAIN ======= -->
    <main id="main">
      <!-- ======= BAŞLIK ======= -->
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">Muhtarlar</h1>
                <span class="color-text-b"
                  >{{ Belediyebilgiler.belediyeadi }} BELEDİYE BAŞKANLIĞI</span
                >
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Anasayfa</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Muhtarlar
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- =======  REHBER ======= -->

      <!-- ======= İHALELER ======= -->
      <section class="property-grid grid">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="row">
                <div
                  v-for="(uye, index) in Uyeler.slice(0, 12)"
                  :key="index"
                  class="col-md-4"
                >
                  <div class="card-box-a card-shadow">
                    <div
                      style="height: 350px; object-fit: cover"
                      class="img-box-a"
                    >
                      <img :src="uye.kapak" alt="" class="img-a img-fluid" />
                    </div>

                    <div class="card-overlay">
                      <div class="card-overlay-a-content">
                        <div class="card-header-a">
                          <h2 class="card-title-a">
                            <a href="#">{{ uye.muhtarAdi }}</a>
                          </h2>
                        </div>

                        <div class="card-body-a">
                          <div class="price-box d-flex">
                            <span class="price-a">{{ uye.mahalleAdi }}</span>
                          </div>
                        </div>

                        <div class="card-footer-a">
                          <ul
                            class="card-info d-flex justify-content-around text-center"
                          >
                            <li>
                              <h4 class="card-info-title">
                                {{ uye.muhtarlikAdresi }}
                              </h4>
                              <span
                                ><a
                                  :href="`tel:${uye.muhtarTel}`"
                                  class="link-a"
                                  >Telefon: {{ uye.muhtarTel }}
                                  <!-- <span class="bi bi-chevron-right"></span>  -->
                                </a></span
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Sidebar />
          </div>
          <div class="row">
            <div class="col-sm-9">
              <nav class="pagination-a">
                <ul class="pagination justify-content-end">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">
                      <span class="bi bi-chevron-left"></span>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">3</a>
                  </li>
                  <li class="page-item next">
                    <a class="page-link" href="#">
                      <span class="bi bi-chevron-right"></span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- =======  İHALELER ======= -->
    </main>
    <!-- ======= MAIN ======= -->
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "Muhtarlar",
  data() {
    return {
      Uyeler: {},
      Belediyebilgiler: {},
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Muhtarlar");
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    const uyemap = this.$store.getters.getMuhtarlar;
    this.Uyeler = Object.values(uyemap);
    this.Uyeler.sort(function (b, a) {
      return a.uyeId - b.uyeId;
    });
  },
  components: {
    Sidebar,
  },
};
</script>

<style scoped>
</style>
